import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["apiKey", "requestForm", "serverUrl"]

  static values = { documentationId: String }

  connect() {
    this.localStorageName = `documentation:${this.documentationIdValue}:api-key`
    this.#setApiKeys()
  }

  changeServer(event) {
    const server = event.currentTarget.dataset.serverUrl
    // Note: this inter-controller communication works only because
    // both controllers are attached to the same DOM element
    const sendRequestController = this.application.getControllerForElementAndIdentifier(this.element, "explorer-send-request")
    this.serverUrlTarget.textContent = server
    this.#setApiKeys()
    sendRequestController.updateServer(server)
  }

  resetApiKey() {
    this.apiKeyTarget.value = ""
    const apiKeys = this.#retrieveApiKeys()
    delete apiKeys[this.serverUrlTarget.textContent]
    localStorage.setItem(this.localStorageName, JSON.stringify(apiKeys))
  }

  // let users get entered tokens back when switching servers
  // TODO: add js helper debounce
  updateApiKey(event) {
    const apiKey = event.currentTarget.value
    const apiKeys = this.#retrieveApiKeys()
    apiKeys[this.serverUrlTarget.textContent] = apiKey
    localStorage.setItem(this.localStorageName, JSON.stringify(apiKeys))
    this.dispatch("updatedApiKey", { detail: { apiKey: apiKey } })
  }

  #setApiKeys() {
    const server = this.serverUrlTarget.textContent
    const apiKeys = this.#retrieveApiKeys()
    const apiKey = apiKeys && apiKeys[server] ? apiKeys[server] : ""
    this.apiKeyTarget.value = apiKey
    this.dispatch("updatedApiKey", { detail: { apiKey: apiKey } })
  }

  #retrieveApiKeys() {
    return JSON.parse(localStorage.getItem(this.localStorageName)) || {}
  }
}
